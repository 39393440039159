import notificationStore, { ERROR } from '@/store/modules/notifications';
import useSettingsStore from '@/store/modules/settings';
import api from '@/utils/api';
import { defineStore } from 'pinia';

export default defineStore('env', {
  state: () => ({
    siteId: null,
    siteEnv: null,
    env: null,
  }),
  getters: {
    isLoaded: (state) => state.env !== null,
    isLive: (state) => state.env.environment === 'live',
    isSiteReady: (state) => state.env?.db_created ?? false,
    team: (state) => state.env.team,
    billing: (state) => state.env.billing,
    // TODO: https://app.clickup.com/t/4558226/SPARK-5964
    siteIdFromSlug: (state) => state.env.team.slugged_name,
    acronymTeamName: (state) => state.env.team.name.match(/\b\w/g)
      ?.join('')
      .toUpperCase()
      .substring(0, 2),
    isAdvancedFeaturesEnabled:
      (state) => state.env.billing?.is_advanced_features_enabled || false,
    isPartner: (state) => state.env?.team?.is_partner || false,
    plan: (state) => state.env.billing?.plan_key,
    environment: (state) => state.env.environment,
    // @todo long term remove the below but the backend doesn't care about plus or not
    // but the dashboard does
    syncLogPlatform: (state) => (state.env.platform === 'shopify_plus' ? 'shopify' : state.env.platform),
  },
  actions: {
    setData({ siteId, siteEnv, env }) {
      this.siteId = siteId;
      this.siteEnv = siteEnv;
      this.env = env;
    },
    async loadEnv(input) {
      const settingsStore = useSettingsStore();

      let { siteId, siteEnv } = this;
      if (input?.siteId) {
        siteId = input.siteId;
      }
      if (input?.siteEnv) {
        siteEnv = input.siteEnv;
      }

      // Avoid loading environment if site id and site env have not changed
      if (input !== undefined && this.siteId === siteId && this.siteEnv === siteEnv) {
        return;
      }

      let env = null;
      try {
        const { data } = await api.get(`/${siteId}/${siteEnv}`);
        env = data;
      } catch (err) {
        notificationStore.notify(
          'Error',
          `Sorry an error has occurred, please try again. If you're still having issues, please contact our support team.`,
          ERROR,
        );
      }

      this.setData({ siteId, siteEnv, env });
      settingsStore.loadSettings(env);
    },
  },
});
